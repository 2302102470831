import ReactDOM from 'react-dom';
import React, {FC, ReactElement} from 'react';

import ThemeProvider from '@mui/material/styles/ThemeProvider';
import {StyledEngineProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from '../theme';

export const Page: FC<{children: React.ReactNode}> = ({children}) =>
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme()}>
      <CssBaseline/>
      {children}
    </ThemeProvider>
  </StyledEngineProvider>;

export const render = (element: ReactElement) => {
  const target = document.getElementById('page');
  if (target) ReactDOM.render(element, target);
};
