import React from 'react';
import {Page, render} from './Page';
import pages from './pages.mjs';

render(<Page>
  <div style={{margin: 20}}>
    <h1>Wikiloc Basemaps</h1>
    <ul>
      {pages.map(page =>
        <li key={page}>
          <a href={page} target="_blank" rel="noreferrer">{page}</a>
        </li>
      )}
    </ul>
  </div>
</Page>);
